<template>
  <div class="overview-lookalikes">
    <div class="text-h6 font-weight-bold mb-4">
      Audience Lookalikes
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="isThemeLight ? 'black' : null"
            v-bind="attrs"
            v-on="on"
          >
            help_outline
          </v-icon>
        </template>
        <span>
          Audience lookalikes help you find influencers that have similar audience.
        </span>
      </v-tooltip>
    </div>

    <v-card class="border-radius-1em box-shadow-soft d-flex flex-column justify-between">
      <v-card-text class="flex-grow-1">
        <v-list dense two-line>
          <v-list-item
            v-for="item in items.slice(0, 3)"
            :key="item.url"
            @click="redirectToWeb(item.url)"
          >
            <v-list-item-avatar>
              <img :src="proxyUrl(item.imageUrl)" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.displayName }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ nFormatter(item.followers) }} followers,
                {{ nFormatter(item.engagements) }} engagements
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          text
          small
          @click="shouldShowDialog = true"
        >
          View More
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="shouldShowDialog"
      width="500"
      scrollable
    >
      <v-card>
        <v-card-title>
          Audience Lookalikes
        </v-card-title>

        <v-card-text class="make-it-scrollable">
          <v-list dense two-line>
            <v-list-item
              v-for="item in items"
              :key="'item-' + item.url"
              @click="redirectToWeb(item.url)"
            >
              <v-list-item-avatar>
                <img :src="proxyUrl(item.imageUrl)" />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.displayName }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ nFormatter(item.followers) }} followers,
                  {{ nFormatter(item.engagements) }} engagements
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            color="primary"
            @click="shouldShowDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Export the SFC
export default {
  // Name of the component
  name: "AudienceLookalikes",

  // Accept incoming data from parent
  props: {
    // The list items
    items: {
      type: Array,
      required: true
    }
  },

  // Define local data variables
  data: () => ({
    shouldShowDialog: false
  })
}
</script>
